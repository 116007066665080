import { v as bvVar } from 'bv';
import { getJSON, postJSON } from 'bv-fetch';

const securityChecksRequest = (values) => postJSON(`/security_checks/${bvVar('locale')}/call_me_back`, values);

export const submitForm = async (values) => {
  const requestCallbackResponse = await postJSON('/api/request_a_callback', values);
  if (requestCallbackResponse.success) {
    return requestCallbackResponse;
  }

  return securityChecksRequest(values);
};

export const validateMobileNumber = (phoneNumber) => (
  postJSON('/bv_api/account/mobile_number', {
    phone_number: phoneNumber.number,
    country_iso: phoneNumber.country_iso,
  })
);

export const apiFetchMobileNumber = () => getJSON('/bv_api/account/mobile_number');

export const apiFetchMobileCountries = () => getJSON('/bv_api/account/mobile_phone_countries');
