import { FORM_ERROR } from 'final-form';
import { t } from 'bv-i18n';
import { submitForm, validateMobileNumber } from '../api';

const mobileNumberValidation = ({
  validatedPhoneNumber, phone_number: phoneNumber,
}) => {
  if (validatedPhoneNumber?.number === `+${phoneNumber.dial_code}${phoneNumber.number}`) {
    return { valid: true };
  }

  return validateMobileNumber(phoneNumber);
};

export const submitHandler = (setResponse) => async (values) => {
  try {
    const response = await mobileNumberValidation(values);
    if (response.valid) {
      const userData = {
        callbackTime: values.callbackTime,
        mobile: values.phone_number.dial_code + values.phone_number.number,
        queryDetails: values.queryDetails,
      };

      const submitResponse = await submitForm(userData);
      return setResponse(submitResponse);
    }
    return {
      phone_number: response.message || t('javascript.account_form.errors.general'),
    };
  } catch (e) {
    return {
      [FORM_ERROR]: t('errors_something_wrong'),
    };
  }
};
