import PropTypes from 'prop-types';
import { Select } from 'bv-components';
import callbackTimes from '../data/callback_times';

const CallbackTime = ({ label, form }) => {
  const onSelectChange = ({ target }) => form.change('callbackTime', target.value);
  return (
    <div className="request-callback__time">
      <label className="request-callback__time-label" htmlFor="request-callback">
        {label}
      </label>
      <Select
        id="request-callback"
        options={callbackTimes()}
        onChange={onSelectChange}
      />
    </div>
  );
};

CallbackTime.propTypes = {
  label: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object).isRequired,
};

export default CallbackTime;
