import PropTypes from 'prop-types';
import classnames from 'classnames';

const QueryDetails = ({
  input, label, meta: {
    touched, error, dirty, active,
  },
}) => {
  const inputClassNames = classnames('bvs-form-control request-callback__query', {
    error: error && touched,
    active,
    'with-value': dirty,
  });

  return (
    <div className="bvs-form-group">
      <textarea {...input} className={inputClassNames} id={input.name} required />
      <label className="bvs-form-control-placeholder" htmlFor={input.name}>{label}</label>
      {touched && Boolean(error) && <span className="bvs-form-error-msg">{error}</span>}
    </div>
  );
};

QueryDetails.propTypes = {
  input: PropTypes.instanceOf(Object).isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.instanceOf(Object).isRequired,
};

export default QueryDetails;
