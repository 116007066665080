import { getValidationFunction } from 'validation-helpers';
import { t } from 'bv-i18n';
import PhoneNumber from '../components/phone_number';
import CallbackTime from '../components/callback_time';
import QueryDetails from '../components/query_details';

export default [
  {
    component: CallbackTime,
    name: 'callbackTime',
    label: t('javascript.kyc.callback.callback_time'),
  },
  {
    component: PhoneNumber,
    name: 'phone_number',
    label: t('javascript.kyc.callback.mobile_number'),
  },
  {
    component: QueryDetails,
    name: 'queryDetails',
    label: t('javascript.kyc.callback.details_query'),
    validate: getValidationFunction([
      {
        type: 'minimumCharacters',
        value: 3,
        errorMessage: t('javascript.kyc.callback.error.invalid_char_length'),
      },
      {
        type: 'maximumCharacters',
        value: 200,
        errorMessage: t('javascript.kyc.callback.error.invalid_char_length'),
      },
    ]),
  },
];
