import { Field, Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import { Card, Icon, Modal } from 'bv-components';
import { t } from 'bv-i18n';
import { submitHandler } from '../../helpers';
import requestCallbackFields from '../../data/request_callback_fields';

const RequestCallbackView = ({
  goBack, response, setResponse, initialValues,
}) => (
  <Form
    onSubmit={submitHandler(setResponse)}
    initialValues={initialValues}
  >
    {({
      submitting, handleSubmit, submitError, form, values,
    }) => (
      <form onSubmit={handleSubmit}>
        <div className="request-callback">
          <h3>{t('javascript.kyc.callback.description')}</h3>
          <Card>
            { requestCallbackFields.map((field) => (
              <Field key={field.name} form={form} {...field} />
            ))}
            <button type="submit" className="bvs-button account-form__submit-button" disabled={submitting}>
              <Icon id="arrow-right" />
            </button>
          </Card>
        </div>
        {!isEmpty(response) && (
          <Modal
            success={response.success}
            danger={!response.success}
            actions={[
              {
                label: t('close'),
                primary: response.success,
                danger: !response.success,
                onClick: response.success ? goBack : () => setResponse(undefined),
              },
            ]}
          >
            <p>{response.success ? t('javascript.kyc.callback.thanks_message') : t('javascript.errors_something_wrong')}</p>
          </Modal>
        )}
        {submitError && (
          <Modal
            danger
            actions={[
              {
                label: t('close'),
                danger: true,
                onClick: () => form.reset({ ...values }),
              },
            ]}
          >
            <p>{submitError}</p>
          </Modal>
        )}
      </form>
    )}
  </Form>
);

RequestCallbackView.propTypes = {
  response: PropTypes.instanceOf(Object).isRequired,
  setResponse: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  initialValues: PropTypes.instanceOf(Object).isRequired,
};

export default RequestCallbackView;
