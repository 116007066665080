import { connect } from 'react-redux';
import { compose } from 'underscore';
import { fetchMobileNumber, fetchMobileCountries } from '../../ducks';
import RequestCallbackContainer from './request_callback_container';

const mapStateToProps = (state) => ({
  mobileNumber: state.requestCallback.mobileNumber,
  loaded: state.requestCallback.loadedNumber && state.requestCallback.loadedCountries,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMobile: compose(dispatch, fetchMobileNumber),
  fetchCountries: compose(dispatch, fetchMobileCountries),
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestCallbackContainer);
