import { ModalWindow } from 'bv-components';
import { t } from 'bv-i18n';

import reducer from '../ducks';
import RequestCallback from './request_callback/request_callback_redux';

const { addReducers } = window.reduxState;

addReducers({
  requestCallback: reducer,
});

const RequestCallbackApp = () => (
  <ModalWindow title={t('javascript.kyc.contact_us.callback')} className="request-callback-modal">
    <RequestCallback />
  </ModalWindow>
);

export default RequestCallbackApp;
