import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'bv-components';
import { useHistory } from 'react-router-dom';
import RequestCallbackView from './request_callback_view';
import callbackTimes from '../../data/callback_times';

const RequestCallbackContainer = ({
  loaded, mobileNumber, fetchMobile, fetchCountries,
  handleSubmit,
}) => {
  useEffect(() => {
    fetchMobile();
    fetchCountries();
  }, []);

  const [response, setResponse] = useState(undefined);
  const history = useHistory();
  const goBack = () => history.replace('/pages/contact-us?first_modal=true');

  if (!loaded) return <Spinner />;

  return (
    <RequestCallbackView
      onSubmit={handleSubmit}
      response={response}
      setResponse={setResponse}
      goBack={goBack}
      initialValues={{
        callbackTime: callbackTimes()[0].value,
        phone_number: mobileNumber,
      }}
    />
  );
};

RequestCallbackContainer.propTypes = {
  loaded: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchMobile: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  mobileNumber: PropTypes.instanceOf(Object).isRequired,
};

export default RequestCallbackContainer;
